<script setup lang="ts">
    import { usePriceFormatter } from '@/composables/formatPrice';
    import type { Cart } from '@containex/portal-backend-api-client';
    import Divider from 'primevue/divider';
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';

    const props = defineProps<{
        cart: Cart;
        small: boolean;
    }>();

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();

    const cranageLineItems = computed(() => {
        return props.cart.items.filter((item) => item.parent_line_item_id != null);
    });

    const cranageTotal = computed(() => {
        return cranageLineItems.value.reduce((sum, current) => sum + (current.total ?? 0), 0);
    });

    const cranageSplitted = computed(() => cranageTotal.value / 2);

    const cranageQuantity = computed(() => {
        const quantity = cranageLineItems.value.map((item) => item.quantity).reduce((sum, current) => sum + current, 0);
        return quantity / 2;
    });

    const formattedCranagePrice = computed(() => {
        return formatPrice(cranageSplitted.value ?? 0);
    });
</script>
<template>
    <div class="cart-sub-price-container">
        <div class="cart-price-label" :class="{ 'text-md-regular-line-height-auto': small }">
            {{ t('CART.TOTAL.CRANAGE_IN') }}
            <div class="cranage-quantity color-secondary text-md-regular-line-height-auto">
                {{ t('CART.CART_ITEM.QUANTITY') }}: {{ cranageQuantity }}
            </div>
        </div>
        <div :class="{ 'text-md-regular-line-height-auto': small }">{{ formattedCranagePrice }}</div>
    </div>
    <Divider class="divider" />
    <div class="cart-sub-price-container">
        <div class="cart-price-label" :class="{ 'text-md-regular-line-height-auto': small }">
            {{ t('CART.TOTAL.CRANAGE_OUT') }}
            <div class="cranage-quantity color-secondary text-md-regular-line-height-auto">
                {{ t('CART.CART_ITEM.QUANTITY') }}: {{ cranageQuantity }}
            </div>
        </div>
        <div :class="{ 'text-md-regular-line-height-auto': small }">{{ formattedCranagePrice }}</div>
    </div>
    <Divider class="divider" />
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .cart-sub-price-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: main.$spacing-5;
        padding-bottom: main.$spacing-5;
    }

    .cranage-quantity {
        padding-top: main.$spacing-2;
    }

    .cart-price-label {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .divider {
        margin: 0;
    }
</style>
